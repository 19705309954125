/**
 * アップロードファイルをアップロード前にプレビューする
 * 「.js__filePreview」で実行
 * 「data-preview」が必須。表示するエレメントを指定。
 <input type="file" class="js__filePreview" data-preview="#filePreview">
 <span id="filePreview"></span>
 */

export namespace InputFilePreviewModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.inputFilePreviewSetEvent()
				Service.inputFilePreviewDeleteSetEvent()
			})
		}

		public static inputFilePreviewSetEvent() {
			const $targetElements = document.querySelectorAll(
				'.js__filePreview[type="file"][data-preview]'
			)
			$targetElements.forEach(($targetElement) => {
				const $preview = $targetElement.getAttribute("data-preview")
				if ($preview) {
					const $previewElements =
						document.querySelectorAll<HTMLElement>($preview)
					$previewElements.forEach(($previewElement) => {
						$previewElement.addEventListener("dragover", ($event) => {
							$previewElement.classList.add("js__dragover")
						})

						$previewElement.addEventListener("dragleave", ($event) => {
							$previewElement.classList.remove("js__dragover")
						})

						$previewElement.addEventListener("drop", ($event) => {
							$previewElement.classList.remove("js__dragover")
						})

						$previewElement.addEventListener("mouseout", ($event) => {
							$previewElement.classList.remove("js__dragover")
						})
					})

					$targetElement.addEventListener("change", ($event) => {
						const $eventTarget = $event.target as HTMLInputElement
						let $flg = true
						let $file: File

						if ($eventTarget) {
							if ($eventTarget.files === null) {
								$flg = false
							} else {
								$file = $eventTarget.files[0]
								if ($eventTarget.files && $eventTarget.files.length > 1) {
									alert("アップロードできる画像は1枚です。再度お試しください。")
									return
								}
								if ($file === null || $eventTarget.files.length > 1) {
									$flg = false
								}
							}

							const $previewElements =
								document.querySelectorAll<HTMLElement>($preview)
							$previewElements.forEach(($previewElement) => {
								$previewElement.classList.remove("js__dragover")

								if ($flg) {
									const $fileReader = new FileReader()
									$fileReader.readAsDataURL($file)
									$fileReader.onload = () => {
										$previewElement.style.backgroundImage =
											'url("' + $fileReader.result + '")'
									}
								} else {
									$previewElement.style.backgroundImage = ""
								}
							})

							if (!$flg) {
								alert(
									"アップロードファイルに不備がありました。再度お試しください。"
								)
							}
						}
					})
				}
			})
		}

		public static inputFilePreviewDeleteSetEvent() {
			const $targetElements = document.querySelectorAll(
				".js__filePreviewDelete[data-target]"
			)
			$targetElements.forEach(($targetElement) => {
				const $target = $targetElement.getAttribute("data-target")
				if ($target) {
					const $inputElements =
						document.querySelectorAll<HTMLInputElement>($target)

					$inputElements.forEach(($inputElement) => {
						$targetElement.addEventListener("click", ($event) => {
							$inputElement.value = ""
							const $preview = $inputElement.getAttribute("data-preview")
							if ($preview) {
								const $previewElements =
									document.querySelectorAll<HTMLElement>($preview)
								$previewElements.forEach(($previewElement) => {
									$previewElement.style.backgroundImage = ""
								})
							}

							return false
						})
					})
				}
			})
		}
	}
}
