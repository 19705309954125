/**
 * 店舗展開日未定の場合
 * 「.js__store-targetdate-unselect」に対して処理をする
 */

export namespace ToggleTargetDateModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.toggleTargetDateEvent()
			})
		}

		public static toggleTargetDateEvent() {
			const $triggerElement = document.getElementsByClassName(
				"js__store-targetdate-unselect"
			)[0] as HTMLInputElement

			if (!$triggerElement) {
				return
			}

			const $targetElement = document.getElementsByName(
				"store_target_date"
			)[0] as HTMLInputElement
			if (!$targetElement) {
				return
			}

			let value = $targetElement.value

			$targetElement.addEventListener("change", () => {
				value = $targetElement.value
			})

			$triggerElement.addEventListener("change", () => {
				if ($triggerElement.checked) {
					$targetElement.disabled = true
					$targetElement.value = ""
				} else {
					$targetElement.disabled = false
					$targetElement.value = value
				}
			})
		}
	}
}
