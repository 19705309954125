/**
 * オートページャー
 * 「.js__autoPager.js__scroll--custom」に対して処理をする
 * 「data-taraget」が必須
 * 「data-baseurl」が必須
<div class="js__autoPager js__scroll--custom"
  data-target="#autoPagerTarget"
  data-baseurl="http://localhost/api/xxx/yyy/[page]/zzz/"></div>
 */

import axios from "axios"
import { functions } from "../../../../modules/functions"

export namespace AutoPagerModule {
	export class Service {
		private static PAGE_AUTO_LOOP = 0

		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.setEvent()
			})
		}

		public static setEvent() {
			const $elements = document.querySelectorAll<HTMLElement>(
				".js__autoPager.js__scroll[data-target][data-baseurl]"
			)
			$elements.forEach(($element) => {
				// スクロールイベント定義
				const ScrollEvent = () => {
					const $page = Number.parseInt(
						$element.getAttribute("data-page") ?? "0"
					)

					if ($element.classList.contains("js__autoPager--complete")) {
						// スクロールイベント停止処理
						console.log("restApi: complete")
						$element.removeEventListener("js__scroll--inCustom", ScrollEvent)
					} else {
						// スクロールイベント処理開始
						if ($element.classList.contains("js__autoPager--loading")) {
							return
						}

						if (
							$page === 0 ||
							$page % Service.PAGE_AUTO_LOOP ||
							!Service.PAGE_AUTO_LOOP
						) {
							$element.classList.add("js__autoPager--loading")
							$element.classList.remove("js__autoPager--onHold")
							Service.restApi($element)
						} else {
							$element.classList.add("js__autoPager--onHold")
						}
					}
				}

				// スクロールイベント登録
				const $autoLoad = $element.getAttribute("data-autoload")
				if ("false" !== $autoLoad) {
					$element.addEventListener("js__scroll--inCustom", ScrollEvent)

					// 初回はスクロールイベントを強制発火
					functions.trigger($element, "js__scroll--inCustom")
				} else {
					Service.checkNext($element)
					$element.classList.add("js__autoPager--onHold")
				}

				// クリックイベント
				const $button = $element.querySelector(".button")
				if ($button) {
					$button.addEventListener("click", () => {
						$element.classList.add("js__autoPager--loading")
						Service.restApi($element)
					})
				}
			})
		}

		private static checkNext($element: HTMLElement) {
			const $page = Number.parseInt($element.getAttribute("data-page") ?? "0")
			const $target = $element.getAttribute("data-target")
			const $baseUrl = $element.getAttribute("data-baseurl")
			const $completeFlag = true

			if ($target && $baseUrl) {
				const $targetElement = document.querySelector($target)

				if ($targetElement) {
					const $nextPage = String($page + 1)
					const $url = $baseUrl.replace("[page]", $nextPage)
					const $autoLoad = $element.getAttribute("data-autoload")

					// 通信処理
					axios({
						method: "GET",
						url: $url,
					})
						.then(($response) => {
							if (!$response.data) {
								$element.classList.add("js__autoPager--complete")
							}
						})
						.catch(($error) => {
							console.log($error)
							alert("ERROR: 100\nエラーが発生したため、リロードします。")
							location.reload()
							return $completeFlag
						})
				}
			}
		}

		private static restApi($element: HTMLElement) {
			const $page = Number.parseInt($element.getAttribute("data-page") ?? "0")
			const $target = $element.getAttribute("data-target")
			const $baseUrl = $element.getAttribute("data-baseurl")
			const $completeFlag = true

			if ($target && $baseUrl) {
				const $targetElement = document.querySelector($target)

				if ($targetElement) {
					const $nextPage = String($page + 1)
					const $url = $baseUrl.replace("[page]", $nextPage)
					const $autoLoad = $element.getAttribute("data-autoload")

					// 通信処理
					axios({
						method: "GET",
						url: $url,
					})
						.then(($response) => {
							if ($response.data) {
								const $nodeList = Service.htmlToNode(
									$response.data,
									$targetElement
								)
								if ($nodeList && $nodeList.length) {
									$nodeList.forEach(($node) => {
										$targetElement.appendChild($node)
									})

									$element.setAttribute("data-page", $nextPage)

									$element.classList.remove("js__autoPager--loading")

									if ("false" !== $autoLoad) {
										$element.classList.remove("js__autoPager--onHold")

										const $body = document.querySelector("body")
										if ($body) {
											functions.trigger($body, "scroll")
											functions.trigger($body, "resize")

											if ($element.classList.contains("js__scroll--custom")) {
												functions.trigger($element, "js__scroll--inCustom")
											}
										}

										const $mainPanel = document.querySelector("#mainPanel")
										if ($mainPanel) {
											if ($mainPanel.scrollHeight === window.innerHeight) {
												console.log("#mainPanel: scroll")
											}
										}

										$element.classList.add("js__autoPager--onHold")
									} else {
										Service.checkNext($element)
									}
								}
							} else {
								$element.classList.add("js__autoPager--complete")
							}
						})
						.catch(($error) => {
							console.log($error)
							/**
             * TODO:コメントアウト
             * ページ遷移時の対応がよくわからないので一旦コメントアウト
            if ($error.response) {
              // 要求がなされたとサーバがステータスコードで応答した
              // 2XXの範囲外
              console.log($error.response.data);
              console.log($error.response.status);
              console.log($error.response.headers);
              alert('ERROR: 178\nエラーが発生したため、リロードします。');
            } else if ($error.request) {
              // 要求がなされたが、応答が受信されなかった
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log($error.request);
              alert('ERROR: 184\nエラーが発生したため、リロードします。');
            } else {
              // トリガーしたリクエストの設定に何かしらのエラーがある
              console.log('Error', $error.message);
              alert('ERROR: 188\nエラーが発生したため、リロードします。');
            }
            location.reload();
            return $completeFlag;
             */
						})
				}
			}
		}

		private static htmlToNode(
			$htmlStr: object,
			$targetElement: HTMLElement | Element
		) {
			if (!$htmlStr || typeof $htmlStr !== "string") return

			const $tmpElmt = document.createElement(
				$targetElement.nodeName.toLowerCase()
			)

			// 高速処理するが対応ブラウザを考えinnerHTMLを使用
			$tmpElmt.innerHTML = $htmlStr // tmpElmt.insertAdjacentHTML('beforeend', htmlStr);

			return $tmpElmt.childNodes
		}

		private static axiosRequestAbort() {}
	}
}
