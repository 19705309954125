/**
 * 入力チェック
 * 「.js__inputCheck」に対して処理をする
 * 「data-target」必須 ターゲットのIDなどを指定
 * 「data-toggle」必須 トグルする属性を指定
 *
<a class="js__inputCheck" data-target="[name='input01'],[name='input02']" data-toggle="disabled">
  ボタン
</a>

<input type="text" name="input01">
<input type="password" name="input02">
 */

export namespace InputCheckModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.InputCheckEvent()
			})
		}

		public static InputCheckEvent() {
			const $elements = document.querySelectorAll(
				".js__inputCheck[data-target][data-toggle]"
			)
			if ($elements) {
				$elements.forEach(($element) => {
					const $self = $element
					const $target = $element.getAttribute("data-target")
					const $attribute = $element.getAttribute("data-toggle")
					const $defaultToggle = $element.getAttribute("data-defaultToggle")
					if ($self && $attribute && null === $defaultToggle) {
						if (null !== $self.getAttribute($attribute)) {
							$self.setAttribute("data-defaultToggle", "on")
						} else {
							$self.setAttribute("data-defaultToggle", "off")
						}
					}
					if ($target && $attribute) {
						const $targetElements = document.querySelectorAll($target)
						if ($targetElements) {
							$targetElements.forEach(($targetElement) => {
								$targetElement.addEventListener("chenge", ($event) => {
									Service.InputCheckEventAction($self, $targetElements)
								})
								$targetElement.addEventListener("keyup", ($event) => {
									Service.InputCheckEventAction($self, $targetElements)
								})
								$targetElement.addEventListener("blur", ($event) => {
									Service.InputCheckEventAction($self, $targetElements)
								})
							})
							// 初期値でチェックを実行
							// フォームの自動入力時には既にinputに値が入っている場合があるため
							requestIdleCallback(() =>
								Service.InputCheckEventAction($self, $targetElements)
							)
						}
					}
				})
			}
		}

		/**
		 * チェック処理
		 * @param $targetSource 属性を変更するエレメント
		 * @param $targetDestination チェック対象エレメント
		 */
		public static InputCheckEventAction(
			$targetSource: Element | HTMLElement,
			$targetDestination: NodeListOf<Element>
		) {
			const $attribute = $targetSource.getAttribute("data-toggle")
			const $default = $targetSource.getAttribute("data-defaultToggle")
			let $checkFlg: boolean | null = null

			if ($targetDestination && $attribute) {
				$targetDestination.forEach(($target) => {
					const $value = ($target as HTMLInputElement).value

					if (null !== $value) {
						if (null === $checkFlg) {
							$checkFlg = true
						}

						$checkFlg = $checkFlg && $value !== ""

						if (!$checkFlg) {
							return false
						}
					}
				})

				if ("on" === $default && $checkFlg) {
					$targetSource.removeAttribute($attribute)
				} else if ("on" === $default && !$checkFlg) {
					$targetSource.setAttribute($attribute, $attribute)
					if ("href" === $attribute) {
						$targetSource.setAttribute($attribute, "#")
					}
				} else if ("off" === $default && !$checkFlg) {
					$targetSource.removeAttribute($attribute)
				} else if ("off" === $default && $checkFlg) {
					$targetSource.setAttribute($attribute, $attribute)
					if ("href" === $attribute) {
						$targetSource.setAttribute($attribute, "#")
					}
				}
			}
		}
	}
}
