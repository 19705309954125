/**
 * 分割アップロード
 */
import axios from "axios"
import Flow from "@flowjs/flow.js"

export namespace FlowjsFileUploadModule {
	export class Service {
		private static CHUNK_SIZE = 1 * 1024 * 1024

		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.setEvent()
			})
		}

		public static setEvent() {
			const $forms = document.querySelectorAll<HTMLFormElement>(
				'form[enctype="multipart/form-data"]'
			)
			$forms.forEach(($form) => {
				const $singleFile = $form.getAttribute("data-singleFile")
				const $emptyfile = $form.getAttribute("data-emptyfile")
				const $apiurl = $form.getAttribute("data-apiurl")
				const $apideleteurl = $form.getAttribute("data-apideleteurl")
				const $forItems =
					$form.querySelectorAll<HTMLInputElement>('input[type="file"]')
				const $csrfToken = document.querySelector('meta[name="csrf-token"]')
				const $submitButtons =
					$form.querySelectorAll<HTMLInputElement>('[type="submit"]')

				if (!$form.querySelector(".progressBar")) {
					const $progressBar = document.createElement("div")
					$progressBar.classList.add("progressBar")
					$form.appendChild($progressBar)
				}
				const $progressBar = $form.querySelector(".progressBar")

				const $accept = $form.getAttribute("data-accept")?.split(",")

				if (
					$csrfToken &&
					$apiurl &&
					$forItems &&
					$progressBar &&
					$apideleteurl &&
					$submitButtons
				) {
					const $filePaths = $form.querySelectorAll('[name="filePath[]"]')
					if ($emptyfile !== "ok") {
						if ($filePaths.length) {
							let $flg = true
							$filePaths.forEach(($filePath) => {
								if (!$filePath.closest(".progressBar__item--complete")) {
									return ($flg = false)
								}
							})
							if ($flg) {
								$submitButtons.forEach(($submitButton) => {
									$submitButton.removeAttribute("disabled")
								})
							} else {
								$submitButtons.forEach(($submitButton) => {
									$submitButton.setAttribute("disabled", "disabled")
								})
							}
						} else {
							$submitButtons.forEach(($submitButton) => {
								$submitButton.setAttribute("disabled", "disabled")
							})
						}
					}

					const $flow = new Flow({
						target: $apiurl,
						chunkSize: Service.CHUNK_SIZE,
						forceChunkSize: true,
						headers: {
							"X-CSRF-TOKEN": $csrfToken.getAttribute("content") ?? "",
						},
						allowDuplicateUploads: true,
						testChunks: false,
						permanentErrors: [404, 500, 501],
						singleFile: $singleFile === "singleFile",
					})

					$flow.assignBrowse([].slice.call($forItems))

					$flow.on("fileSuccess", ($file, $message) => {
						// アップロード完了したときの処理

						// inputWrap--file__filename
						const $fileFileName = $form.querySelector(
							".inputWrap--file__filename.isLoading"
						)
						if (
							$forItems.length === 1 &&
							!$forItems[0].hasAttribute("multiple") &&
							$fileFileName
						) {
							$fileFileName.classList.remove("isLoading")
						}

						const $uniqueIdentifier = $file.uniqueIdentifier
						if ($uniqueIdentifier) {
							const $progressBar = document.querySelector(
								'[data-uniqueIdentifier="' + $uniqueIdentifier + '"]'
							)
							const $parent = $progressBar?.closest(".progressBar__item")
							if ($progressBar && $parent) {
								const $progressBarMain =
									$progressBar.querySelector<HTMLElement>(
										".progressBar__itemBarMain"
									)
								const $progressBarText = $progressBar.querySelector(
									".progressBar__itemBarMainText"
								)
								if ($progressBarMain && $progressBarText) {
									$progressBarMain.style.width = "100%"
									$progressBarText.textContent = "100%"
								}

								const $inputFilePath = document.createElement("input")
								$inputFilePath.classList.add(
									"progressBar__inputCompleteFilePath"
								)
								$inputFilePath.setAttribute("type", "hidden")
								$inputFilePath.setAttribute("name", "filePath[]")
								$inputFilePath.value = $message
								$progressBar.appendChild($inputFilePath)

								const $inputUniqueIdentifier = document.createElement("input")
								$inputUniqueIdentifier.classList.add(
									"progressBar__inputCompleteUniqueIdentifier"
								)
								$inputUniqueIdentifier.setAttribute("type", "hidden")
								$inputUniqueIdentifier.setAttribute(
									"name",
									"uniqueIdentifier[]"
								)
								$inputUniqueIdentifier.value = $uniqueIdentifier
								$progressBar.appendChild($inputUniqueIdentifier)

								$parent.classList.add("progressBar__item--complete")
							}
						}

						if (!$flow.isUploading()) {
							$submitButtons.forEach(($submitButton) => {
								$submitButton.removeAttribute("disabled")
							})
						}
					})

					$flow.on("fileError", ($file, $message) => {
						// Reflect that the file upload has resulted in error
						alert(
							"エラーが発生したためリロードします。\n何度も同じエラーが発生する場合はサーバー管理者に問い合わせてください。"
						)
						location.reload()
					})

					$flow.on("fileProgress", ($file) => {
						//プログレスバーの実行
						//flow.progress() で進捗が取得できるのでそれを利用してプログレスバーを設定
						//$('.bar').css({width:Math.floor(flow.progress()*100) + '%'});
						const $uniqueIdentifier = $file.uniqueIdentifier
						if ($uniqueIdentifier) {
							const $progressBar = document.querySelector(
								'[data-uniqueIdentifier="' + $uniqueIdentifier + '"]'
							)
							if ($progressBar) {
								const $progressBarMain =
									$progressBar.querySelector<HTMLElement>(
										".progressBar__itemBarMain"
									)
								const $progressBarText = $progressBar.querySelector(
									".progressBar__itemBarMainText"
								)
								if ($progressBarMain && $progressBarText) {
									$progressBarMain.style.width =
										Math.floor($file.progress() * 100).toString() + "%"
									$progressBarText.textContent =
										Math.floor($file.progress() * 100).toString() + "%"
								}
							}
						}
					})

					$flow.on("filesSubmitted", ($file, $event) => {
						// アップロード実行
						$submitButtons.forEach(($submitButton) => {
							$submitButton.setAttribute("disabled", "disabled")
						})
						$flow.upload()

						if (!$flow.isUploading()) {
							$submitButtons.forEach(($submitButton) => {
								$submitButton.removeAttribute("disabled")
							})
						}
					})

					$flow.on("fileAdded", ($file) => {
						const $extension = Service.getExt($file.name)
						if ($accept && $accept.indexOf($extension) === -1) {
							$flow.removeFile($file)
							alert("「" + $file.name + "」は、アップロードできません。")
							if (!$flow.isUploading()) {
								$submitButtons.forEach(($submitButton) => {
									$submitButton.removeAttribute("disabled")
								})
							}
							return false
						}

						if (
							document.querySelector(
								'[data-uniqueIdentifier="' + $file.uniqueIdentifier + '"]'
							)
						) {
							$flow.removeFile($file)
							if (!$flow.isUploading()) {
								$submitButtons.forEach(($submitButton) => {
									$submitButton.removeAttribute("disabled")
								})
							}
							return false
						}

						if (
							$forItems.length === 1 &&
							!$forItems[0].hasAttribute("multiple")
						) {
							const $progressBarItem = $form.querySelector(".progressBar__item")
							const $deleteButton =
								$progressBarItem?.querySelector<HTMLElement>(
									".progressBar__itemDeleteButton"
								)

							if ($progressBarItem && $deleteButton) {
								$deleteButton.click()
							}
						}

						// inputWrap--file__filename
						const $fileFileName = $form.querySelector(
							".inputWrap--file__filename"
						)
						if (
							$forItems.length === 1 &&
							!$forItems[0].hasAttribute("multiple") &&
							$fileFileName
						) {
							$fileFileName.textContent = $file.name
							$fileFileName.classList.add("isLoading")

							const $inputFileneme = $form.querySelector<HTMLInputElement>(
								'input[name="filename"]'
							)
							if ($inputFileneme) {
								$inputFileneme.value = $file.name
							}
						}

						const $progressBarItem = document.createElement("div")
						$progressBarItem.classList.add("progressBar__item")

						// image
						const $progressBarItemImageWrap = document.createElement("div")
						$progressBarItemImageWrap.classList.add("progressBar__itemImage")

						const $progressBarItemImageBox = document.createElement("div")
						$progressBarItemImageBox.classList.add("progressBar__itemImageBox")
						$progressBarItemImageBox.classList.add("imageBox")
						$progressBarItemImageBox.setAttribute("data-extension", $extension)
						const $fileReader = new FileReader()
						if (Array("jpg", "jpeg", "png", "gif").indexOf($extension) !== -1) {
							$fileReader.readAsDataURL($file.file)
							$fileReader.onload = () => {
								$progressBarItemImageBox.style.backgroundImage =
									'url("' + $fileReader.result + '")'
							}
						}
						$progressBarItemImageWrap.appendChild($progressBarItemImageBox)

						// progressBar
						const $progressBarItemBarWrap = document.createElement("div")
						const $progressBarItemBarFileName = document.createElement("p")
						const $progressBarItemBar = document.createElement("div")
						const $progressBarItemBarText = document.createElement("p")
						$progressBarItemBarWrap.classList.add("progressBar__itemBar")
						$progressBarItemBarWrap.setAttribute(
							"data-uniqueIdentifier",
							$file.uniqueIdentifier
						)
						$progressBarItemBarFileName.classList.add(
							"progressBar__itemBarFileName"
						)
						$progressBarItemBarFileName.textContent = $file.name
						$progressBarItemBar.classList.add("progressBar__itemBarMain")
						$progressBarItemBarText.classList.add(
							"progressBar__itemBarMainText"
						)
						$progressBarItemBarText.textContent = "0%"
						$progressBarItemBar.appendChild($progressBarItemBarText)
						$progressBarItemBarWrap.appendChild($progressBarItemBarFileName)
						$progressBarItemBarWrap.appendChild($progressBarItemBar)

						// deleteButton
						const $progressBarItemButtonWrap = document.createElement("div")
						const $progressBarItemButton = document.createElement("a")
						const $progressBarItemButtonText = document.createElement("span")
						$progressBarItemButtonWrap.classList.add("progressBar__itemButton")
						$progressBarItemButton.classList.add("button")
						$progressBarItemButton.classList.add(
							"progressBar__itemDeleteButton"
						)
						$progressBarItemButton.setAttribute("href", "#")
						$progressBarItemButton.setAttribute(
							"data-apideleteurl",
							$apideleteurl
						)
						$progressBarItemButton.setAttribute(
							"data-uniqueIdentifier",
							$file.uniqueIdentifier
						)
						$progressBarItemButtonText.classList.add("button__text")
						$progressBarItemButtonText.textContent = "削除"
						$progressBarItemButton.appendChild($progressBarItemButtonText)
						$progressBarItemButtonWrap.appendChild($progressBarItemButton)

						$progressBarItem.appendChild($progressBarItemImageWrap)
						$progressBarItem.appendChild($progressBarItemBarWrap)
						$progressBarItem.appendChild($progressBarItemButtonWrap)
						$progressBar.appendChild($progressBarItem)
					})
				}
			})

			// deleteaction
			Service.deleteButton()
		}

		public static deleteButton() {
			document.addEventListener("click", ($event) => {
				const $eventTarget = $event.target as HTMLElement
				if ($eventTarget) {
					let $target: HTMLElement | null = null
					if (
						$eventTarget.classList.contains("progressBar__itemDeleteButton")
					) {
						$target = $eventTarget
					} else if ($eventTarget.closest(".progressBar__itemDeleteButton")) {
						$target = $eventTarget.closest(".progressBar__itemDeleteButton")
					}

					if ($target) {
						const $form = $target.closest("form")
						const $emptyfile = $form?.getAttribute("data-emptyfile")
						const $url = $form?.getAttribute("data-apideleteurl")
						const $uniqueIdentifier = $target.getAttribute(
							"data-uniqueIdentifier"
						)
						const $parent = $target.closest(".progressBar__item")

						if ($url && $uniqueIdentifier && $parent && $form) {
							const $progressBarItemBar = document.querySelector(
								'.progressBar__itemBar[data-uniqueIdentifier="' +
									$uniqueIdentifier +
									'"]'
							)
							if ($progressBarItemBar) {
								const $file_path =
									$progressBarItemBar.querySelector<HTMLInputElement>(
										".progressBar__inputCompleteFilePath"
									)?.value
								if ($file_path) {
									// 通信処理
									axios({
										method: "GET",
										url: $url,
										params: {
											file_path: $file_path,
										},
									})
										.then(($response) => {
											$parent.remove()
											if (
												!document.querySelector(".progressBar__item") &&
												$emptyfile !== "ok"
											) {
												const $submitButtons =
													$form.querySelectorAll('[type="submit"]')
												$submitButtons.forEach(($submitButton) => {
													$submitButton.setAttribute("disabled", "disabled")
												})
											}
										})
										.catch(($error) => {
											console.log($error)
											alert("エラーが発生したため、リロードします。")
											location.reload()
											return
										})
								} else {
									console.log("line: 290")
									alert("エラーが発生したため、リロードします。")
									location.reload()
								}
							} else {
								console.log("line: 297")
								alert("エラーが発生したため、リロードします。")
								location.reload()
							}
						} else {
							console.log("line: 304")
							alert("エラーが発生したため、リロードします。")
							location.reload()
						}
					}
				}
			})
		}

		private static getExt($filename: string) {
			var pos = $filename.lastIndexOf(".")
			if (pos === -1) return ""
			return $filename.slice(pos + 1).toLowerCase()
		}
	}
}
