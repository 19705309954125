/**
 * クライアント側データBOXサイドバー
 * .clientDataboxSidebarに対して処理をする
 */

export namespace ClientDataboxSidebarModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.setEvent()
			})
		}

		public static setEvent() {
			const $sidebars = document.querySelectorAll(".clientDataboxSidebar")
			if ($sidebars) {
				$sidebars.forEach(($sidebar) => {
					Service.insertNavItems($sidebar)

					const $databoxlist = document.querySelector(
						".adminDataBox__panelMain .columnBox"
					)
					if ($databoxlist) {
						const $observer = new MutationObserver(($mutations) => {
							// observer.disconnect(); // 監視を終了
							//console.table($mutations);

							$mutations.forEach(($mutation) => {
								if ($mutation.addedNodes) {
									$mutation.addedNodes.forEach(($addedNode) => {
										const $target = ($addedNode as Element).querySelector(
											'.dataBoxListLink[data-extension="folder"]'
										)
										if ($target) {
											Service.insertNavItem($sidebar, $target)
										}
									})
								}
							})
						})

						$observer.observe($databoxlist, {
							attributes: false, // 属性変化の監視
							attributeOldValue: false, // 変化前の属性値を matation.oldValue に格納する
							characterData: false, // テキストノードの変化を監視
							characterDataOldValue: false, // 変化前のテキストを matation.oldValue に格納する
							childList: true, // 子ノードの変化を監視
							subtree: true, // 子孫ノードも監視対象に含める
						})
					}
				})
			}
		}

		public static insertNavItem($sidebar: Element, $target: Element) {
			const $item = document.createElement("div")
			const $link = document.createElement("a")
			const $text = document.createElement("span")

			$text.classList.add("sidebar__navListItemListItemLinkText")
			$text.textContent = $target.querySelector(".dataBoxListLink__caption")
				?.textContent as string

			$link.classList.add("sidebar__navListItemListItemLink")
			$link.setAttribute("href", $target.getAttribute("href") as string)
			$link.append($text)

			$item.classList.add("sidebar__navListItemListItem")
			$item.append($link)

			$sidebar.append($item)
		}

		public static insertNavItems($sidebar: Element) {
			const $navListItemListItems = $sidebar.querySelectorAll(
				".sidebar__navListItemListItem:not(.sidebar__navListItemListItem--default)"
			)
			$navListItemListItems.forEach(($navListItemListItem) => {
				$navListItemListItem.remove()
			})

			const $dataBoxListLinks = document.querySelectorAll(
				'.adminDataBox__panelMain .dataBoxListLink[data-extension="folder"]'
			)
			$dataBoxListLinks.forEach(($dataBoxListLink) => {
				Service.insertNavItem($sidebar, $dataBoxListLink)
			})
		}
	}
}
