/**
 * データボックスリンクを１度目は選択状態、２度目はリンクとする
 */

export namespace DataBoxListLinkModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.setEvent()
			})
		}

		public static setEvent() {
			const $parent = document.querySelector(".adminDataBox__panelMain")
			const $sidebar = document.querySelector(".adminDataBox__panelSidebar")
			if ($parent && $sidebar) {
				$parent.addEventListener("click", ($event) => {
					const $target = $event.target as HTMLElement
					let $link: HTMLElement | null

					if ("a" === $target.nodeName) {
						$link = $target
					} else if ($target.closest("a")) {
						$link = $target.closest("a")
					} else {
						$link = null
					}

					if ($link && $link.classList.contains("dataBoxListLink")) {
						const $sliderData = $link.nextElementSibling
						if (
							$sliderData?.classList.contains("dataBoxListLink__sidebarData")
						) {
							if (
								!$sliderData.classList.contains("dataBoxListLink--isSelected")
							) {
								$event.preventDefault()
								const $linkSelecteds = document.querySelectorAll(
									".dataBoxListLink--isSelected"
								)
								$linkSelecteds.forEach(($linkSelected) => {
									$linkSelected.classList.remove("dataBoxListLink--isSelected")
								})
								$sliderData.classList.add("dataBoxListLink--isSelected")
								Service.setSelectedElement($sliderData)
								return false
							}
						}
					}
				})
			}

			/**
       * TODO: コメントアウト
      const $elements = document.querySelectorAll('.dataBoxListLink');
      const $sidebar = document.querySelector('.adminDataBox__panelSidebar');
      if ($sidebar) {
        $elements.forEach($element => {
          const $sliderData = $element.nextElementSibling;
          if ($sliderData?.classList.contains('dataBoxListLink__sidebarData')) {
            $element.addEventListener('click', ($event) => {
              if (!$element.classList.contains('dataBoxListLink--isSelected')) {
                $event.preventDefault();
                const $linkSelecteds = document.querySelectorAll('.dataBoxListLink--isSelected');
                $linkSelecteds.forEach($linkSelected => {
                  $linkSelected.classList.remove('dataBoxListLink--isSelected');
                });
                $element.classList.add('dataBoxListLink--isSelected');
                Service.setSelectedElement($element);
                return false;
              }
            });
          }
        });
      }
       */
		}

		public static setSelectedElement($sidebarData: Element) {
			const $sidebar = document.querySelector(".adminDataBox__panelSidebar")
			const $clone = $sidebarData.cloneNode(true) as HTMLElement
			if ($clone) {
				const $modal = $clone.querySelector<HTMLElement>(".modal")
				if ($modal) {
					if ($modal.parentNode) {
						$modal.parentNode.removeChild($modal)
					}
				}
			}
			if ($sidebar) {
				$sidebar.insertBefore($clone, $sidebar.firstElementChild)

				const $oldElements = $sidebar.querySelectorAll(
					".dataBoxListLink__sidebarData + .dataBoxListLink__sidebarData"
				)
				$oldElements.forEach(($oldElement) => {
					if ($oldElement.parentNode) {
						$oldElement.parentNode.removeChild($oldElement)
					}
				})
			}
		}
	}
}
