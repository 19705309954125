/**
 * メッセージボックス
 * 「.js__messageListBox .js__messageListBoxAllTrigger」をトリガー元のエレメントとする
 * 「.js__messageListBox .js__messageListBoxTrigger」をトリガー元のエレメントとする
 * 「.js__messageListBoxTarget」の開閉をする
 */

import { functions } from "../../../../modules/functions"

export namespace MessageListBoxModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.messageListBoxSetEvent()
			})
		}

		public static messageListBoxSetEvent() {
			Service.allTriggerSetEvent()
			Service.triggerSetEvent()
		}

		public static triggerSetEvent() {
			const $targetSelfElements = document.querySelectorAll<HTMLElement>(
				".js__messageListBox .js__messageListBoxTargetRoot .js__messageListBoxSelfTarget"
			)
			$targetSelfElements.forEach(($targetSelfElement) => {
				$targetSelfElement.addEventListener("click", ($event) => {
					$event.preventDefault()
					const $parent = $targetSelfElement.closest(".js__messageListBox")
					const $allTriggerElement = $parent?.querySelector(
						".js__messageListBoxAllTrigger"
					)
					const $targetElementRoot = $targetSelfElement.closest(
						".js__messageListBoxTargetRoot"
					)
					if ($targetElementRoot) {
						const $targetElement =
							$targetElementRoot.querySelector<HTMLElement>(
								".js__messageListBoxTarget"
							)
						if ($targetElement) {
							if (
								$targetElement.classList.contains(
									"js__messageListBoxTarget--isActive"
								)
							) {
								$targetElement.classList.remove(
									"js__messageListBoxTarget--isActive"
								)
								$targetElementRoot?.classList.remove(
									"js__messageListBoxTarget--isActive"
								)
								functions.slideUp($targetElement)
							} else {
								$targetElement.classList.add(
									"js__messageListBoxTarget--isActive"
								)
								$targetElementRoot?.classList.add(
									"js__messageListBoxTarget--isActive"
								)
								functions.slideDown($targetElement)
							}
						}
					}

					if ($parent && $allTriggerElement) {
						const $targetElements = $parent.querySelectorAll(
							".js__messageListBoxTargetRoot .js__messageListBoxTarget"
						)
						let $flg = true
						$targetElements.forEach(($targetElement) => {
							$flg =
								$flg &&
								$targetElement.classList.contains(
									"js__messageListBoxTarget--isActive"
								)
						})
						if ($flg) {
							$allTriggerElement.classList.add(
								"js__messageListBoxTarget--isAllActive"
							)
						} else {
							$allTriggerElement.classList.remove(
								"js__messageListBoxTarget--isAllActive"
							)
						}
					}
				})
			})
		}

		public static allTriggerSetEvent() {
			const $elements = document.querySelectorAll(
				".js__messageListBox .js__messageListBoxAllTrigger"
			)
			$elements.forEach(($element) => {
				const $parent = $element.closest(".js__messageListBox")
				if ($parent) {
					const $targetElements = $parent.querySelectorAll<HTMLElement>(
						".js__messageListBoxTargetRoot .js__messageListBoxTarget"
					)
					let $flag = true
					$targetElements.forEach(($targetElement) => {
						const $targetElementRoot = $targetElement.closest(
							".js__messageListBoxTargetRoot"
						)
						if (
							$targetElement.classList.contains(
								"js__messageListBoxTarget--isActive"
							)
						) {
							$targetElementRoot?.classList.add(
								"js__messageListBoxTarget--isActive"
							)
							$targetElement.style.display = "none"
						} else {
							$flag = false
						}
					})

					if ($flag) {
						$element.classList.add("js__messageListBoxTarget--isAllActive")
					}

					$element.addEventListener("click", ($event) => {
						$event.preventDefault()

						$targetElements.forEach(($targetElement) => {
							const $targetElementRoot = $targetElement.closest(
								".js__messageListBoxTargetRoot"
							)
							if (
								$element.classList.contains(
									"js__messageListBoxTarget--isAllActive"
								)
							) {
								if (
									$targetElement.classList.contains(
										"js__messageListBoxTarget--isActive"
									)
								) {
									$targetElement.classList.remove(
										"js__messageListBoxTarget--isActive"
									)
									$targetElementRoot?.classList.remove(
										"js__messageListBoxTarget--isActive"
									)
									functions.slideUp($targetElement)
								}
							} else {
								if (
									!$targetElement.classList.contains(
										"js__messageListBoxTarget--isActive"
									)
								) {
									$targetElement.classList.add(
										"js__messageListBoxTarget--isActive"
									)
									$targetElementRoot?.classList.add(
										"js__messageListBoxTarget--isActive"
									)
									functions.slideDown($targetElement)
								}
							}
						})

						$element.classList.toggle("js__messageListBoxTarget--isAllActive")
					})
				}
			})
		}
	}
}
