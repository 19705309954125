/**
 * フッター
 */

import { functions } from "../../../modules/functions"

export namespace DetailFooterModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.detailFooterSetEvent()
			})
		}

		public static detailFooterSetEvent() {
			const $elements = document.querySelectorAll<HTMLElement>(
				'.detailFooter:not([data-none_accordion_action="true"])'
			)
			if ($elements) {
				$elements.forEach(($element) => {
					const $form = $element.querySelector("form")
					const $inputFile =
						$form?.querySelectorAll<HTMLInputElement>('input[name="file"]')
					if ($form && $inputFile && $inputFile.length === 1) {
						$form.addEventListener("dragover", ($event) => {
							$event.stopPropagation()
							$event.preventDefault()

							$form.classList.add("js__dragover")
						})

						$form.addEventListener("dragleave", ($event) => {
							$form.classList.remove("js__dragover")
						})

						$form.addEventListener("drop", ($event) => {
							$event.stopPropagation()
							$event.preventDefault()

							$form.classList.remove("js__dragover")
							const $files = $event.dataTransfer?.files
							if ($files) {
								/**
                if($files.length > 1){
                  return alert('アップロードできるファイルは1つだけです。');
                }
                 */
								$inputFile[0].files = $files

								let $EVENT
								try {
									$EVENT = new Event("change")
								} catch (e) {
									$EVENT = document.createEvent("Event")
									$EVENT.initEvent("change", false, true)
								}
								$inputFile[0].dispatchEvent($EVENT)
								$element.click()
							}
						})

						$form.addEventListener("mouseout", ($event) => {
							$form.classList.remove("js__dragover")
						})
					}

					$element.addEventListener("click", ($event) => {
						const $target = $event.target as Element
						if ($target) {
							if (
								$target.classList.contains("detailFooter") ||
								$target
									.closest(".detailFooter__body")
									?.classList.contains("detailFooter__body")
							) {
								const $slideTargets = $element.querySelectorAll<HTMLElement>(
									".js__detailFooterSlide"
								)
								if (!$element.classList.contains("detailFooter--isActive")) {
									$element.classList.add("detailFooter--isActive")
									$slideTargets.forEach(($slideTarget) => {
										functions.slideDown($slideTarget)
									})
								}
							}
						}
					})

					const $errorMessage = $element.querySelector(".errorMessageBox")
					if ($errorMessage) {
						$element.click()
					}

					$element.dataset.accordionReady = "true"
				})

				document.addEventListener("click", ($event) => {
					const $target = $event.target as Element
					if ($target) {
						if (
							!$target.classList.contains("detailFooter") &&
							!$target
								.closest(".detailFooter__body")
								?.classList.contains("detailFooter__body")
						) {
							const $detailFooterSlides =
								document.querySelectorAll<HTMLElement>(
									".detailFooter.detailFooter--isActive .js__detailFooterSlide"
								)
							$detailFooterSlides.forEach(($detailFooterSlide) => {
								const $detailFooter = $detailFooterSlide.closest(
									".detailFooter.detailFooter--isActive"
								)
								if ($detailFooter) {
									$detailFooter.classList.remove("detailFooter--isActive")
								}
								functions.slideUp($detailFooterSlide)
							})
						}
					}
				})
			}
		}
	}
}
