import { Offset } from "./offset"

export namespace ScrollEventModule {
	export class Service {
		constructor(language?: string) {
			document.addEventListener("loadCompleteAction", () => {
				const $body = document.querySelector("html")
				const $mainPanel = document.querySelector<HTMLElement>("#mainPanel")
				//console.log(document.querySelector('html')?.scrollTop);
				if ($body && $mainPanel) {
					document.addEventListener(
						"scroll",
						($event) => {
							const $targetElements =
								document.querySelectorAll<HTMLElement>(".js__scroll")

							if ($targetElements.length) {
								let $scrollTop: number = $body.scrollTop
								const $bodyHeight: number = $mainPanel.clientHeight
								const $windowHeight: number = window.innerHeight
								let $endPoint: number = $scrollTop + $windowHeight + 100
								const $adminBar = document.getElementById("wpadminbar")

								//console.log($scrollTop);

								if ($adminBar) {
									$scrollTop += $adminBar.offsetHeight
									$endPoint =
										$scrollTop + ($windowHeight - $adminBar.offsetHeight) + 10
								}
								for (let $i = 0; $i < $targetElements.length; $i++) {
									const $targetElement = $targetElements[$i] as HTMLElement

									// 画面上
									//console.log(Offset.getTop($targetElement) <= $scrollTop);
									//console.log($scrollTop);
									//console.log($targetElement.offsetTop);
									if (
										Offset.getTop($targetElement) <= $scrollTop ||
										$bodyHeight <= $endPoint
									) {
										if (!$targetElement.classList.contains("js__scroll--top")) {
											$targetElement.classList.add("js__scroll--top")

											let $EVENT
											try {
												$EVENT = new Event("js__scroll--inTop")
											} catch (e) {
												$EVENT = document.createEvent("Event")
												$EVENT.initEvent("js__scroll--inTop", false, true)
											}
											$targetElement.dispatchEvent($EVENT)
										}
										if (
											!$targetElement.classList.contains("js__scroll--fixTop")
										) {
											$targetElement.classList.add("js__scroll--fixTop")
										}
									} else {
										if ($targetElement.classList.contains("js__scroll--top")) {
											$targetElement.classList.remove("js__scroll--top")

											let $EVENT
											try {
												$EVENT = new Event("js__scroll--outTop")
											} catch (e) {
												$EVENT = document.createEvent("Event")
												$EVENT.initEvent("js__scroll--outTop", false, true)
											}
											$targetElement.dispatchEvent($EVENT)
										}
									}

									// 画面中央
									if (
										Offset.getTop($targetElement) <=
											$scrollTop + $windowHeight * 0.5 ||
										$bodyHeight <= $endPoint
									) {
										if (
											!$targetElement.classList.contains("js__scroll--half")
										) {
											$targetElement.classList.add("js__scroll--half")

											let $EVENT
											try {
												$EVENT = new Event("js__scroll--inHalf")
											} catch (e) {
												$EVENT = document.createEvent("Event")
												$EVENT.initEvent("js__scroll--inHalf", false, true)
											}
											$targetElement.dispatchEvent($EVENT)
										}
										if (
											!$targetElement.classList.contains("js__scroll--fixHalf")
										) {
											$targetElement.classList.add("js__scroll--fixHalf")
										}
									} else {
										if ($targetElement.classList.contains("js__scroll--half")) {
											$targetElement.classList.remove("js__scroll--half")

											let $EVENT
											try {
												$EVENT = new Event("js__scroll--outHalf")
											} catch (e) {
												$EVENT = document.createEvent("Event")
												$EVENT.initEvent("js__scroll--outHalf", false, true)
											}
											$targetElement.dispatchEvent($EVENT)
										}
									}

									// 画面上から70％
									if (
										Offset.getTop($targetElement) <=
											$scrollTop + $windowHeight * 0.7 ||
										$bodyHeight <= $endPoint
									) {
										if (
											!$targetElement.classList.contains("js__scroll--custom")
										) {
											$targetElement.classList.add("js__scroll--custom")

											let $EVENT
											try {
												$EVENT = new Event("js__scroll--inCustom")
											} catch (e) {
												$EVENT = document.createEvent("Event")
												$EVENT.initEvent("js__scroll--inCustom", false, true)
											}
											$targetElement.dispatchEvent($EVENT)
										}
										if (
											!$targetElement.classList.contains(
												"js__scroll--fixCustom"
											)
										) {
											$targetElement.classList.add("js__scroll--fixCustom")
										}
									} else {
										if (
											$targetElement.classList.contains("js__scroll--custom")
										) {
											$targetElement.classList.remove("js__scroll--custom")

											let $EVENT
											try {
												$EVENT = new Event("js__scroll--outCustom")
											} catch (e) {
												$EVENT = document.createEvent("Event")
												$EVENT.initEvent("js__scroll--outCustom", false, true)
											}
											$targetElement.dispatchEvent($EVENT)
										}
									}

									// 画面下
									if (
										Offset.getTop($targetElement) <=
											$scrollTop + $windowHeight ||
										$bodyHeight <= $endPoint
									) {
										if (
											!$targetElement.classList.contains("js__scroll--bottom")
										) {
											$targetElement.classList.add("js__scroll--bottom")

											let $EVENT
											try {
												$EVENT = new Event("js__scroll--inBottom")
											} catch (e) {
												$EVENT = document.createEvent("Event")
												$EVENT.initEvent("js__scroll--inBottom", false, true)
											}
											$targetElement.dispatchEvent($EVENT)
										}
										if (
											!$targetElement.classList.contains(
												"js__scroll--fixBottom"
											)
										) {
											$targetElement.classList.add("js__scroll--fixBottom")
										}
									} else {
										if (
											$targetElement.classList.contains("js__scroll--bottom")
										) {
											$targetElement.classList.remove("js__scroll--bottom")

											let $EVENT
											try {
												$EVENT = new Event("js__scroll--outBottom")
											} catch (e) {
												$EVENT = document.createEvent("Event")
												$EVENT.initEvent("js__scroll--outBottom", false, true)
											}
											$targetElement.dispatchEvent($EVENT)
										}
									}
								}
							}
						},
						{ passive: true }
					)
				}
			})
		}

		private static bodyOrHtml() {
			const $body = document.querySelector("body")
			if ($body) {
				return $body
			} else {
				if (navigator.userAgent.indexOf("WebKit") != -1) {
					return document.body
				}
				return document.querySelector("body")
			}
		}

		private static bodyHeight() {
			if (navigator.userAgent.indexOf("WebKit") != -1) {
				const $body = document.body
				return Math.max($body.scrollHeight, $body.offsetHeight)
			}
			const $html = document.documentElement

			return Math.max(
				$html.clientHeight,
				$html.scrollHeight,
				$html.offsetHeight
			)
		}
	}
}
