export namespace ViewportModule {
	export class Service {
		private static SET_VIEWPORT_VW = 0
		private static SET_VIEWPORT_VH = 0

		constructor(language?: string) {
			document.addEventListener(
				"readCompleteAction",
				() => {
					ViewportModule.Service.setViewport()
					window.addEventListener(
						"resize",
						() => {
							ViewportModule.Service.setViewport()
						},
						false
					)
					window.addEventListener(
						"orientationchange",
						() => {
							ViewportModule.Service.setViewport()
						},
						false
					)
				},
				false
			)
		}

		public static setViewport() {
			const $sw = window.screen.width
			let $timer: any = false
			const $meta_viewport = document.querySelector('meta[name="viewport"]')
			if ($meta_viewport) {
				if ($sw < 375) {
					//デバイス横幅375未満
					$meta_viewport.setAttribute(
						"content",
						"width=375px,user-scalable=0,shrink-to-fit=no"
					)
				} else {
					//それ以外
					$meta_viewport.setAttribute(
						"content",
						"width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no,shrink-to-fit=no"
					)
				}
			}

			if ($timer !== false) {
				clearTimeout($timer)
			}
			$timer = setTimeout(() => {
				ViewportModule.Service.setRootStyle()
			}, 100)
		}

		public static setRootStyle() {
			const $_vw = window.innerWidth
			const $_vh = window.innerHeight
			let $_vmax = window.innerHeight

			if ($_vw > $_vh) {
				$_vmax = $_vw * 0.01
			} else {
				$_vmax = $_vh * 0.01
			}

			if (this.SET_VIEWPORT_VH !== $_vmax && this.SET_VIEWPORT_VW !== $_vw) {
				this.SET_VIEWPORT_VH = $_vmax
				this.SET_VIEWPORT_VW = $_vw
				document.documentElement.style.setProperty(
					"--vmax",
					`${this.SET_VIEWPORT_VH}px`
				)
			}
		}
	}
}
