export namespace DeleteForm {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.setEvent()
			})
		}

		public static setEvent() {
			const $deleteForm = document.querySelectorAll(".js-deleteForm")
			if ($deleteForm.length > 0) {
				for (let i = 0; i < $deleteForm.length; i++) {
					const $form = $deleteForm[i] as HTMLFormElement
					$form.addEventListener("submit", (e) => {
						e.preventDefault()
						const $target = e.target as HTMLFormElement
						const $message =
							$target.getAttribute("data-message") ?? "削除しますか?"
						if ($message) {
							if (confirm($message)) {
								$target.submit()
							}
						} else {
							$target.submit()
						}
					})
				}
			}
		}
	}
}
