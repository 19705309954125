/**
 * トグルクラス
 * 「.js__toggleClass」に対して処理をする
 * 「data-target」必須 ターゲットのIDなどを指定
 * 「data-class」任意 トグルするクラス名を指定
 * 
<a class="js__toggleClass" data-target="#js__toggleClassTarget" data-class="js__toggleClass--isList">
  <span class="js__toggleClass__changeText" data-default="リスト表示" data-change="カラム表示"></span>
</a>

<div id="js__toggleClassTarget">
  ...
</div>
 */

export namespace ToggleClassModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.toggleClassChangeEvent()
			})
		}

		public static toggleClassChangeEvent() {
			const $elements = document.querySelectorAll(
				".js__toggleClass[data-target]"
			)
			if ($elements) {
				$elements.forEach(($element) => {
					$element.addEventListener("click", ($event) => {
						const $self = $element
						const $target = $self.getAttribute("data-target")
						const $toggleClass01 = "js__toggleClass--isActive"
						const $toggleClass02 = $self.getAttribute("data-class")
						$self.classList.toggle($toggleClass01)
						if ($toggleClass02) {
							$self.classList.toggle($toggleClass02)
						}
						if ($target) {
							const $targetElements = document.querySelectorAll($target)
							if ($targetElements) {
								$targetElements.forEach(($targetElement) => {
									$targetElement.classList.toggle($toggleClass01)
									if ($toggleClass02) {
										$targetElement.classList.toggle($toggleClass02)
									}
								})
							}
						}
					})
				})
			}
		}
	}
}
