/**
 * クリック時に指定されたものをクリックする。
 * 「.js__clickAction」
 */

export namespace ClickActionModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.setEvent()
			})
		}

		public static setEvent() {
			const $elements = document.querySelectorAll(
				".js__clickAction[data-target]"
			)
			if ($elements) {
				$elements.forEach(($element) => {
					const $target = $element.getAttribute("data-target")
					if ($target) {
						$element.addEventListener("click", ($event) => {
							const $targetElement =
								document.querySelector<HTMLElement>($target)
							if ($targetElement) {
								if ($targetElement.classList.contains("modal")) {
									let $EVENT
									try {
										$EVENT = new Event("modalOpen")
									} catch (e) {
										$EVENT = document.createEvent("Event")
										$EVENT.initEvent("modalOpen", false, true)
									}
									$targetElement.dispatchEvent($EVENT)
								} else {
									$targetElement.click()
								}
							}
						})
					}
				})
			}
		}
	}
}
